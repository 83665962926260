<template>
	<v-layout column fill-height align-center justify-start>
		<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
		<v-flex scroll-y align-center style="width: 100%; height: 0">
			<TasksManagerFilterCustomer :minimized="minimized" />
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksSideBar',
	components: {
		TasksManagerFilterCustomer: () => ({
			component: import('@/components/TasksManager/Filters/TasksManagerFilterCustomer')
		})
	},
	mixins: [TasksManagerModuleGuard],
	data: function () {
		return {
			minimized: false
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			filters: state => state.tasks.filters
		})
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized }
			]
		},
		onSidebarMaximized: function () {
			this.minimized = false
		},
		onSidebarMinimized: function () {
			this.minimized = true
		}
	}
}
</script>
