<template>
	<v-layout v-if="!loading" fill-height>
		<v-layout column fill-height justify-start>
			<TasksManagerToolbar :minimized="isMinimized" class="shrink" />
			<v-divider />
			<v-flex scroll-y>
				<v-layout v-if="verticalDisplayMode" row fill-height>
					<v-flex justify-start scroll-y fill-height>
						<TasksManagerTaskList :minimized="isMinimized || showDetails" />
					</v-flex>
					<w-flex v-if="showDetails && verticalDisplayMode" id="showTaskDetails" style="width: 100%" justify-start scroll-y resizable data-resizable="width">
						<TasksManagerTaskDetails :task="selectedTask" fill-height @close="showDetails = false" />
					</w-flex>
				</v-layout>
				<v-layout v-else column fill-height>
					<v-flex justify-start scroll-y>
						<TasksManagerTaskList />
					</v-flex>
					<w-flex v-if="showDetails && !verticalDisplayMode" fill-height justify-start scroll-y resizable data-resizable="height">
						<TasksManagerTaskDetails :task="selectedTask" @close="showDetails = false" />
					</w-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";
import ResizableMixin from '@/mixins/ResizableMixin'
import TasksManagerSideBar from '@/components/TasksManager/TasksManagerSideBar'

export default {
	name: 'TasksManager',
	components: {
		TasksManagerToolbar: () => ({
			component: import('@/components/TasksManager/TasksManagerToolbar')
		}),
		TasksManagerTaskList: () => ({
			component: import('@/components/TasksManager/TasksManagerTaskList')
		}),
		TasksManagerTaskDetails: () => ({
			component: import('@/components/TasksManager/TasksManagerTaskDetails')
		})
	},
	mixins: [
		TasksManagerModuleGuard,
		HandleSideBarMixin,
		ResizableMixin
	],
	data: function () {
		return {
			loading: true
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			user: state => state.user.data,
			company: state => state.company.selected,
			verticalDisplayMode: state => state.tasks.verticalDisplayMode,
			selectedTask: state => state.tasks.selected,
			members: state => state.tasks.members,
			statuses: state => state.tasks.statuses,
			themes: state => state.tasks.themes,
			filters: state => state.tasks.filters
		}),
		isMinimized: function () {
			return !this.$vuetify.breakpoint.mdAndUp
		},
		showDetails: {
			get: function () {
				return this.selectedTask !== null
			},
			set: function (newValue) {
				if (newValue !== null && !newValue) {
					this.service.selectTask(null)
				}
			}
		}
	},
	watch: {
		company: {
			handler: function () {
				if (!this.isAccountant) {
					this.service.loadThemes()
				}
			}
		},
		verticalDisplayMode: {
			handler: function () {
				const selection = this.selectedTask
				this.service.selectTask(null)
				this.$forceUpdate()
				setTimeout(() => {
					this.service.selectTask(selection)
				}, 500)
			}
		}
	},
	created: function () {
		this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
		this.loadStaticData()
			.then(() => {
				return this.loadSideBar()
			})
			.finally(() => {
				this.loading = false
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
			})
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.setPermanentSidebar(false)
		this.setHasSidebar(false)
		this.service.resetState()
	},
	methods: {
		loadSideBar: function () {
			let result = Promise.resolve()
			if (this.isAccountant) {
				let TasksManagerSideBarClass = Vue.extend(TasksManagerSideBar)
				const tasksSideBarComponent = new TasksManagerSideBarClass({
					parent: this
				})
				result = this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
					canMinimize: this.$vuetify.breakpoint.mdAndUp,
					canClose: this.$vuetify.breakpoint.smAndDown,
					title: this.$t('client_workspace.tasks.filters.companies'),
					moduleInstance: tasksSideBarComponent,
					emitter: this
				})
				this.setShowSidebar(this.$vuetify.breakpoint.mdAndUp)
				this.setHasSidebar(true)
			}
			return result
		},
		loadStaticData: function () {
			const promises = []
			if (this.isAccountant) {
				promises.push(this.service.loadMembers())
				promises.push(this.service.loadCompanies())
			} else {
				promises.push(this.service.loadThemes())
			}
			promises.push(this.service.loadStatuses())
			promises.push(this.service.loadTypes())
			return Promise.all(promises).then(() => {
				this.initializeFilters()
			})
		},
		initializeFilters: function () {
			this.filters.statuses.clear()
			this.filters.members.clear()
			this.filters.themes.clear()
			const highestStatusOrder = Math.max.apply(
				Math,
				this.statuses.map(status => status.order)
			)
			this.statuses.filter(status => status.order != highestStatusOrder).forEach(status => this.filters.statuses.push(status.id))
			this.members.forEach(member => {
				if (member.id === this.user.id) {
					this.filters.members.push(member.id)
				}
			})
			this.themes.forEach(theme => this.filters.themes.push(theme))
		}
	}
}
</script>

<style scoped>
#showTaskDetails {
	max-width: 45%;
	min-width: 45%;
}
</style>
