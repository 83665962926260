// ============================================================================
// TasksManagerService
// -------------------
// TasksManager module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/TasksManagerApi'

import AppService from '@/services/AppService'

// ---------
// Internals
// ---------
const Private = {
	loadThemes: function (companyList = null) {
		let themeList
		if (companyList) {
			themeList = companyList
				.flatMap(company =>
					company.themes.map(theme => {
						return { title: theme.title, ids: [theme.id], color: theme.color }
					})
				)
				.reduce((results, theme) => {
					if (!results.some(entry => entry.title === theme.title)) {
						results.push(theme)
					} else {
						results
							.filter(entry => entry.title === theme.title)
							.forEach(entry => {
								entry.ids = [...Array.prototype.concat(theme.ids, entry.ids).reduce((unique, id) => (unique.includes(id) ? unique : [...unique, id]), [])]
							})
					}
					return results
				}, [])
		} 
		if (themeList) {
			store.dispatch('tasks/setThemes', themeList)
		}
	},
	getTask: function (taskId) {
		return API.getTask(taskId).then(response => response.data.data)
	},
	retrieveParentTaskInList: function (task, root) {
		let result = null
		const taskArray = root ? root : store.state.tasks.list
		if (taskArray) {
			for (const taskItem of taskArray) {
				if (taskItem && taskItem.children && taskItem.children.some(child => child.id === task.id)) {
					result = taskItem
				} else if (taskItem.children) {
					result = Private.retrieveParentTaskInList(task.id, taskItem.children)
				}
				if (result) {
					break
				}
			}
		}
		return result
	},
	getTaskAccountingFirmType: function () {
		return store.state.tasks.types.find(taskType => taskType.name === 'accounting_firm_task')
	},
	getTaskServiceRequestType: function () {
		return store.state.tasks.types.find(taskType => taskType.name === 'service_request')
	},
	getTaskInformationRequestType: function () {
		return store.state.tasks.types.find(taskType => taskType.name === 'information_request')
	},
	getFiltersAndSortingOptions: function () {
		let result = {}
		Object.assign(result, this.getFiltersOptions())
		Object.assign(result, this.getSortingOptions())
		return result
	},
	getFiltersOptions: function () {
		const result = {}
		const filters = store.state.tasks.filters
		if (filters) {
			Object.assign(result, this.getFiltersAdminOptions(filters))
			Object.assign(result, this.getFiltersSearchOptions(filters))
			Object.assign(result, this.getFiltersStatusOptions(filters))
			Object.assign(result, this.getFiltersMembersOptions(filters))
			Object.assign(result, this.getFiltersThemeOptions(filters))
			Object.assign(result, this.getFiltersInternalOptions(filters))
			Object.assign(result, this.getFiltersDueDateOptions(filters))
		}
		return result
	},
	getFiltersAdminOptions: function (filters) {
		const result = {}
		if (filters.adminMode) {
			result.am = 1
		}
		return result
	},
	getFiltersSearchOptions: function (filters) {
		const result = {}
		if (filters.search && filters.search.trim().length > 0) {
			result.q = filters.search
		}
		return result
	},
	getFiltersStatusOptions: function (filters) {
		const result = {}
		if (filters.statuses && 0 < filters.statuses.length && filters.statuses.length < store.state.tasks.statuses.length) {
			result.s = filters.statuses.join(',')
		}
		return result
	},
	getFiltersMembersOptions: function (filters) {
		const result = {}
		if (filters.members && filters.internalTasks && 0 < filters.members.length && filters.members.length < store.state.tasks.members.length) {
			result.m = filters.members.join(',')
		}
		return result
	},
	getFiltersThemeOptions: function (filters) {
		const result = {}
		if (filters.themes && filters.companies && 0 < filters.themes.length && filters.themes.length < store.state.tasks.themes.length) {
			result.t = filters.themes.flatMap(theme => theme.ids).join(',')
		}
		return result
	},
	getFiltersInternalOptions: function (filters) {
		const result = {}
		if (store.state.auth.isAccountant && filters.companies && 0 < filters.companies.length && filters.companies.length < store.state.tasks.companies.length) {
			result.c = filters.companies.join(',')
		} else if (store.state.auth.isAccountant && filters.companies.length === 0) {
			result.c = 0
		}
		if (store.state.auth.isAccountant) {
			result.i = filters.internalTasks ? 1 : 0
		}
		return result
	},
	getFiltersDueDateOptions: function (filters) {
		const result = {}
		if (filters.dueDateInterval && filters.dueDateInterval.afterDate && filters.dueDateInterval.afterDate.length > 0) {
			result.df = filters.dueDateInterval.afterDate
		}
		if (filters.dueDateInterval && filters.dueDateInterval.beforeDate && filters.dueDateInterval.beforeDate.length > 0) {
			result.dt = filters.dueDateInterval.beforeDate
		}
		return result
	},
	getSortingOptions: function () {
		const result = {}
		if (store.state.tasks.sorting) {
			const sorting = store.state.tasks.sorting
			if (sorting.byCompany == 'asc') {
				result.s_c = 1
			} else if (sorting.byCompany == 'desc') {
				result.s_c = 0
			}
			if (sorting.byDueDate == 'asc') {
				result.s_d = 1
			} else if (sorting.byDueDate == 'desc') {
				result.s_d = 0
			}
			if (sorting.byName == 'asc') {
				result.s_n = 1
			} else if (sorting.byName == 'desc') {
				result.s_n = 0
			}
			if (sorting.byStatus == 'asc') {
				result.s_s = 1
			} else if (sorting.byStatus == 'desc') {
				result.s_s = 0
			}
		}
		return result
	},
	retrieveTaskInList: function (taskId, root = null) {
		let result = null
		const taskArray = root ? root : store.state.tasks.list
		if (taskArray) {
			result = taskArray.find(task => task.id == taskId)
		}
		if (!result && taskArray.some(task => task.children)) {
			const tasksWithChildren = taskArray.filter(task => task.children)
			for (const taskWithChildren of tasksWithChildren) {
				if (taskWithChildren.children) {
					result = Private.retrieveTaskInList(taskId, taskWithChildren.children)
				}
				if (result) {
					break
				}
			}
		}
		return result
	},
	prepareTaskObject: function (task) {
		task.accounting_firm_id = store.state.accountingFirm.selected.id
		if (task.type && !task.type_id) {
			task.type_id = task.type.id
		}
		if (task.type) {
			delete task.type
		}
		if (task.parent && !task.task_id) {
			task.task_id = task.parent.id
		}
		if (task.parent) {
			delete task.parent
		}
		if (task.vendor && !task.vendor_id) {
			task.vendor_id = task.vendor.id
		}
		if (task.vendor) {
			delete task.vendor
			delete task.members
		} else {
			delete task.themes
		}
	},
	setCancelToken: function (callAnswer) {
		store.dispatch('tasks/setCancelToken', { item: 'taskslist', cancelToken: callAnswer.cancelToken })
	},
	requests: {
		accountingFirm: {
			vendor: {
				task: {
					attachments: {
						read: API.accountingFirm.vendor.task.attachments.read
					}
				}
			}
		}
	}
}

// -------
// Exports
// -------
export default {
	copyToClipboard: AppService.copyToClipboard,
	createTask: function (task) {
		Private.prepareTaskObject(task)
		return API.createTask(task)
			.then(response => response.data.data)
			.then(newTask => {
				if (!newTask.isChild) {
					store.dispatch('tasks/addToList', newTask)
				} else {
					let parentTask = Private.retrieveTaskInList(task.task_id)
					if (!parentTask.children) {
						parentTask.children = []
					}
					parentTask.children.push(newTask)
					const status_id = newTask.status.id
					if (!parentTask.childrenStatusesCounters) {
						API.getStatuses()
							.then(response => response.data.data)
							.then(statuses => parentTask.childrenStatusesCounters = [{ status_id: statuses.find(status => status.order === 1).id}])
						
					} else if (!parentTask.childrenStatusesCounters[status_id]) {
						parentTask.childrenStatusesCounters[status_id] = 1
					} else {
						parentTask.childrenStatusesCounters[status_id] = parentTask.childrenStatusesCounters[status_id] + 1
					}
				}
				return newTask
			})
	},
	getTask: Private.getTask,
	updateTask: function (task) {
		let refreshParentTask = false
		if (task.status) {
			refreshParentTask = true
			if (!task.status_id) {
				task.status_id = task.status.id
			}
			delete task.status
		}
		return API.updateTask(task.id, task)
			.then(response => response.data.data)
			.then(updatedTask => {
				if (updatedTask) {
					const existingTaskInstance = Private.retrieveTaskInList(updatedTask.id)
					Object.assign(existingTaskInstance, updatedTask)
					if (refreshParentTask) {
						const parentTask = Private.retrieveParentTaskInList(updatedTask, null)
						if (parentTask) {
							Private.getTask(parentTask.id).then(upToDateParentTask => Object.assign(parentTask, upToDateParentTask))
						}
					}
				}
			})
	},
	deleteTask: function (task) {
		return API.deleteTask(task.id)
	},
	findVendorInformationRequestHoldingTask: function (vendor_id) {
		return API.getTasksList({
			a_id: store.state.accountingFirm.selected.id,
			c_id: vendor_id,
			tl: 'tasks.type.' + Private.getTaskInformationRequestType().name
		}).then(data => (data && data.data && data.data[0] ? data.data[0] : null))
	},
	loadTasksList: function (currentPage = undefined) {
		let params = Private.getFiltersAndSortingOptions()
		params.po = store.state.tasks.paginationOffset
		params.page = currentPage
		params.a_id = store.state.accountingFirm.selected.id
		if (!store.state.auth.isAccountant) {
			params.c_id = store.state.company.selected.id
		}
		return API.getTasksList(params)
			.then(data => {
				store.dispatch('tasks/setList', data.data)
				return data
			})
	},
	getTasksCounters: function () {
		let params = Private.getFiltersAndSortingOptions()
		params.a_id = store.state.accountingFirm.selected.id
		if (!store.state.auth.isAccountant) {
			params.c_id = store.state.company.selected.id
		}
		return API.getCounters(params)
	},
	selectTask: function (task) {
		return store.dispatch('tasks/setSelected', task)
	},
	retrieveParentTaskInList: Private.retrieveParentTaskInList,
	getTaskParents: function getTaskParents (task) {
		let result = []
		if (task && task.parent) {
			const parentTaskId = Private.getTask(task.parent.id)
			result.push(parentTaskId)
			getTaskParents(parentTaskId).forEach(taskParentId => result.push(taskParentId))
		}
		return result
	},
	getFirstTaskStatus: function () {
		return store.state.tasks.statuses.find(status => status.order === 1)
	},
	getTaskAccountingFirmType: Private.getTaskAccountingFirmType,
	getTaskInformationRequestType: function () {
		return store.state.tasks.types.find(taskType => taskType.name === 'information_request')
	},
	isOfTaskAccountingFirmType: function (task) {
		return task && task.type ? task.type.id === Private.getTaskAccountingFirmType().id : false
	},
	isOfTaskServiceRequestType: function (task) {
		return task?.type?.id === Private.getTaskServiceRequestType().id
	},
	isOfTaskInformationRequestType: function (task) {
		return task && task.type ? task.type.id === Private.getTaskInformationRequestType().id : false
	},
	setVerticalDisplayMode: function (value) {
		return store.dispatch('tasks/setVerticalDisplayMode', value)
	},
	setPaginationOffset: function (value) {
		return store.dispatch('tasks/setPaginationOffset', value)
	},
	resetState: function () {
		return store.dispatch('tasks/reset')
	},
	loadCompanies: function (forceRefresh = false, includeAll = false) {
		let result = Promise.resolve()
		if (forceRefresh || store.state.tasks.companies.length === 0) {
			const parameters = {
				a_id: store.state.accountingFirm.selected.id
			}
			if (includeAll) {
				parameters.am = true
			}
			result = API.listVendors(parameters).then(companyList => {
				companyList?.forEach(company => {
					company.clientCode = company.accounting_firms.find(accountingFirm => accountingFirm.id === store.state.accountingFirm.selected.id).pivot.client_code
					company.fullname = company.clientCode ? company.company + ' (' + company.clientCode + ')' : company.company
					company.name = company.clientCode ? company.clientCode : company.company
					company.nameHtml = company.name
				})
				return companyList
			})
			.then(companyList => companyList?.sort(AppService.compareValues('fullname', 'asc')))
			.then(companyList => {
				store.dispatch('tasks/setCompanies', companyList)
				Private.loadThemes(companyList)
			})
				
		}
		return result
	},
	loadMembers: function (forceRefresh = false) {
		let result = Promise.resolve()
		if (forceRefresh || store.state.tasks.members.length === 0) {
			const parameters = {
				a_id: store.state.accountingFirm.selected.id
			}
			result = API.getMembers(parameters)
				.then(response => response.data.data)
				.then(members => {
					members.map(member => {
						member.name = member.firstname + ' ' + member.lastname
						member.abbreviation = member.firstname[0] + '. ' + member.lastname
						member.initials = member.firstname[0].toUpperCase() + member.lastname[0].toUpperCase()
					})
					return members
				})
				.then(members => store.dispatch('tasks/setMembers', members))
		}
		return result
	},
	loadStatuses: function (forceRefresh = false) {
		let result = Promise.resolve()
		if (forceRefresh || store.state.tasks.statuses.length === 0) {
			result = API.getStatuses()
				.then(response => response.data.data)
				.then(statuses => store.dispatch('tasks/setStatuses', statuses))
		}
		return result
	},
	loadThemes: Private.loadThemes,
	loadTypes: function (forceRefresh = false) {
		let result = Promise.resolve()
		if (forceRefresh || store.state.tasks.types.length === 0) {
			result = API.getTypes()
				.then(response => response.data.data)
				.then(typesList => store.dispatch('tasks/setTypes', typesList))
		}
		return result
	},
	getQuickFolders: function (vendorId) {
		return API.getQuickFolders(vendorId).then(response => response.data.data.quickfolders)
	},
	attachFile: function (task, document) {
		let result
		if (!document) {
			result = Promise.reject()
		} else {
			result = API.attachFile(task.id, { documentId: document.id })
				.then(response => response.data.data)
				.then(updatedTask => {
					if (updatedTask) {
						const existingTaskInstance = Private.retrieveTaskInList(updatedTask.id)
						Object.assign(existingTaskInstance, updatedTask)
					}
				})
		}
		return result
	},
	actionsRules: {
		canEdit: function (task) {
			let result = false
			if (task && store.state.auth.isAccountant) {
				result = true
			}
			return result
		}
	},
	listAttachments: Private.requests.accountingFirm.vendor.task.attachments.read
}
