import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments } from '@/helpers/methods'

const createTask = data => {
	const url = `/tasks/`
	return axios.post(url, data)
}

const getTask = taskId => {
	const url = `/tasks/${taskId}`
	return axios.get(url)
}

const updateTask = (taskId, data) => {
	const url = `/tasks/${taskId}`
	return axios.patch(url, data)
}

const deleteTask = taskId => {
	const url = `/tasks/${taskId}`
	return axios.delete(url)
}

const getTasksList = (params) => {
	return Backend.GET(URLS.api.tasks.uri, [], params)
}

const getCounters = (params) => {
	return Backend.GET(URLS.api.tasks.counters.uri, [], params)
}

const listVendors = (params) => {
	return Backend.GET(URLS.api.tasks.vendors.uri, [], params)
}

const getMembers = parameters => {
	const url = `/tasks/members/`
	return axios.get(url, { params: parameters })
}

const getStatuses = () => {
	const url = `/tasks/statuses/`
	return axios.get(url)
}

const getTypes = () => {
	const url = `/tasks/types/`
	return axios.get(url)
}

const getQuickFolders = vendorId => {
	const url = `vendors/${vendorId}/quickfolders`

	return axios.get(url)
}

const attachFile = (taskId, parameters) => {
	const url = `/tasks/${taskId}/documents`
	return axios.post(url, parameters)
}

export default {
	accountingFirm: {
		vendor: {
			task: {
				attachments: {
					read: function (accountingFirmId, vendorId, taskId) {
						RequiredArguments({ accountingFirmId, taskId, vendorId })
						return Backend.GET(URLS.api.accountingFirms.vendors.tasks.documents.uri, [accountingFirmId, vendorId, taskId])
	
					}
				}
			}
		}
	},
	createTask: createTask,
	getTask: getTask,
	updateTask: updateTask,
	deleteTask: deleteTask,
	getTasksList: getTasksList,
	listVendors,
	getMembers: getMembers,
	getStatuses: getStatuses,
	getTypes: getTypes,
	getCounters: getCounters,
	getQuickFolders: getQuickFolders,
	attachFile: attachFile
}
